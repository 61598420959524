html {
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    background-color: #ffffff !important;
    height: 100%;
}

#root {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*:not(:hover) ::-webkit-scrollbar-thumb {
    background-color: transparent;
}
/* Default Scroll */

/* width */

@media print {
    #root {
        zoom: 0.8;
    }
    ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
}

@media screen and (min-width: 768px) {
    ::-webkit-scrollbar {
        width: 6px;
        height: 2px;

        right: 2px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        /* background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px; */
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 5.5px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.36);
    }
}

.ant-picker-input > input {
    font-size: 12px !important;
}

/* mui picker customization */
.MuiPickersBasePicker-container {
    height: 415px;
    position: relative;
}
.MuiPickerDTToolbar-toolbar {
    background-color: inherit !important;
    position: absolute !important;
    transform: translateY(375px) !important;
    min-height: auto !important;
    height: auto !important;
    width: 100%;
    right: 15px;
}
.MuiGrid-justify-xs-center {
    align-items: center;
    justify-content: unset !important;
}
.MuiGrid-grid-xs-5 {
    max-width: 45% !important;
    flex-basis: 45% !important;
    margin-right: 40px !important;
}
.MuiGrid-grid-xs-6 {
    flex-basis: 27% !important;
}
.MuiGrid-direction-xs-column {
    flex-direction: row-reverse !important;
    align-items: center !important;
}
.MuiGrid-grid-xs-1 {
    flex-wrap: nowrap !important;
    flex-direction: row !important;
    flex-basis: 17% !important;
    max-width: 17% !important;
}
.MuiPickersDatePickerRoot-toolbar .MuiPickersToolbarText-toolbarTxt {
    color: #0097d1;
    font-size: 14px;
}
.MuiPickersDatePickerRoot-toolbar .MuiPickersToolbarText-toolbarBtnSelected {
    color: #008ac9;
}
.MuiPickersDay-daySelected {
    background-color: #0097d1 !important;
    color: white !important;
}
.MuiTypography-colorPrimary {
    color: #0097d1 !important;
}
.MuiPickersYear-root:focus {
    color: #0097d1 !important;
}
.MuiPickersToolbarText-toolbarTxt {
    color: #0097d15c !important;
    font-size: 24px !important;
}
.MuiTypography-subtitle1 {
    font-size: 15px !important;
    margin-left: 5px !important;
}
.MuiPickersToolbarText-toolbarBtnSelected {
    color: #0097d1 !important;
}
.MuiPickersTimePickerToolbar-ampmSelection {
    margin: 0 !important;
    flex-direction: row !important;
}
.MuiPickersTimePickerToolbar-ampmLabel {
    font-size: 21px !important;
    margin-right: 5px !important;
}
.MuiPickersClockPointer-pointer {
    background-color: #0097d1 !important;
}
.MuiPickersClockPointer-noPoint {
    background-color: #0097d1 !important;
}
.MuiPickersClockPointer-thumb {
    border: 14px solid #0097d1 !important;
}
.MuiPickersClock-pin {
    background-color: #0097d1 !important;
}
.MuiTypography-alignCenter {
    color: #0097d1 !important;
}
.MuiPickersClock-clock {
    background-color: transparent !important;
    border: 1px solid #0097d1 !important;
}
.MuiPaper-elevation1 {
    box-shadow: none !important;
}
.MuiPickerDTTabs-tabs {
    background: transparent !important;
}
.MuiTab-textColorInherit {
    opacity: 1 !important;
    color: #757575 !important;
}
.MuiTab-textColorInherit.Mui-selected {
    opacity: 1 !important;
    color: #0097d1 !important;
    border-bottom: 3px solid #0097d1 !important;
}
.MuiTabs-indicator {
    background-color: #0097d1 !important;
}

.custom-ant-tooltip-design .ant-tooltip-inner {
    padding: 12px;
    font-size: 12px;
    color: rgb(255, 255, 255);
    background-color: rgb(37, 43, 74);
    border-radius: 3px;
    border: 1px solid rgb(37, 43, 74);
    text-align: center;
}

/* custom popover */
@media screen and (max-width: 768px) {
    #ant-popover-container > div {
        height: 100%;
    }
    .custom-popover {
        left: 0 !important;
        top: 0 !important;
        padding: 0 !important;
        width: 100%;
        height: 100vh;
        background: white;
        overflow-y: overlay;
    }
    .custom-popover .ant-popover-arrow {
        display: none;
        overflow-y: overlay;
    }
    .custom-popover .ant-popover-inner {
        position: absolute;
        overflow-y: overlay;
        width: 100%;
        height: 100vh;
    }
}

/* custom dropdown */
.ant-select-dropdown {
    border: 1px solid rgb(0, 138, 201);
    border-radius: 15px !important;
}

/* custom input clear */
.ant-input-affix-wrapper {
    border-radius: 70px !important;
    border-color: #008ac9 !important;
}

/* custom swiper pagination bullets */
.swiper-pagination-bullet {
    background: none !important;
    border: 1px solid #bdbdbd !important;
    opacity: 1 !important;
}
.swiper-pagination-bullet-active {
    border: none !important;
    background: #008ac9 !important;
}

/* antd image preview */
.ant-image-preview-root {
    position: absolute;
    z-index: 1500;
}
